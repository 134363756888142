import styled from 'styled-components';

export const StyledSubtitle = styled.h3`
  margin: 2rem 0 1rem;

  @media only screen and (min-width: 1024px) {
    font-size: 2.25rem;
    margin: 2rem 0 1.5rem;
  }
`;
