import {
  faAngleLeft,
  faAngleRight,
  faBars,
  faChevronUp,
} from '@fortawesome/free-solid-svg-icons';
import {
  faApple,
  faFacebookSquare,
  faGooglePlay,
} from '@fortawesome/free-brands-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProps } from './types';
import InstagramIcon from './icons/Instagram';
import LinkedInIcon from './icons/Linkedin';
import MailIcon from './icons/Mail';
import { StyledIcon } from './styled';

const renderSvg = (color: string, type: string): JSX.Element | null => {
  switch (type) {
  case 'apple':
    return <FontAwesomeIcon icon={faApple} color={color} />;
  case 'chevronLeft':
    return <FontAwesomeIcon icon={faAngleLeft} color={color} />;
  case 'chevronRight':
    return <FontAwesomeIcon icon={faAngleRight} color={color} />;
  case 'chevronUp':
    return <FontAwesomeIcon icon={faChevronUp} color={color} />;
  case 'facebook':
    return <FontAwesomeIcon icon={faFacebookSquare} color={color} />;
  case 'googlePlay':
    return <FontAwesomeIcon icon={faGooglePlay} color={color} />;
  case 'menu':
    return <FontAwesomeIcon icon={faBars} color={color} />;
  case 'instagram':
    return <InstagramIcon />;
  case 'linkedin':
    return <LinkedInIcon />;
  case 'mail':
    return <MailIcon />;
  default:
    return null;
  }
};

export const Icon = ({
  className = '',
  color = 'var(--primary_light_03)',
  height,
  onClick = () => {},
  scaleOnDesktop = false,
  type,
  width,
}: IconProps): JSX.Element | null => {
  return (
    <StyledIcon
      className={className}
      height={height}
      width={width}
      onClick={onClick}
      $scaleOnDesktop={scaleOnDesktop}
    >
      {renderSvg(color, type)}
    </StyledIcon>
  );
};
