import styled from 'styled-components';

export const StyledLogo = styled.a<{ height: string; width: string }>`
  cursor: pointer;
  // font-size: 1.25rem;
  // line-height: inherit;
  margin-right: 1rem;
  white-space: nowrap;

  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    height: ${({ height }) => (height ? height : '2.25rem')};
    width: ${({ width }) => width};
  }

  @media only screen and (min-width: 936px) {
    margin-left: 2rem;
    margin-right: 0;
  }

  @media only screen and (min-width: 1024px) {
    border-right: 3px solid #5d9deb;
    padding-right: 2rem;
  }
`;
