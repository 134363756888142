import { Caption, Wrapper } from './styled';

import Link from 'next/link';
import NextImage from 'next/image';
import { ProjectProps } from './types';
import { useMemo } from 'react';

export const Project = ({
  caption = '',
  height,
  image,
  link = '',
  name,
}: ProjectProps): JSX.Element => {
  const renderImage = useMemo(() => (
    <Wrapper height={height}>
      <NextImage
        alt={name}
        placeholder="blur"
        src={image}
        style={ { height: '100%', width: '100%', objectFit: 'cover' } }
      />
      {caption && <Caption>{caption}</Caption>}
    </Wrapper>
  ), [caption, height, image, name]);

  if (link) {
    return (
      <Link href={link} passHref>
        {renderImage}
      </Link>
    );
  }

  return renderImage;
};
