import styled, { css } from 'styled-components';

export const StyledTabs = styled.ul`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  list-style: none;
  margin-bottom: 0;
  padding-left: 0;

  @media only screen and (min-width: 936px) {
    gap: 2rem;
    margin-bottom: 1rem;
  }
`;

export const Tab = styled.li`
  @media only screen and (min-width: 936px) {
    height: 4rem;
    line-height: 3rem;
  }
`;

export const Link = styled.div<{ $active: boolean }>`
  background-color: transparent;
  border-bottom: 5px solid transparent;
  border-radius: 0;
  color: var(--grey);
  cursor: pointer;
  padding: 0.5rem 1rem;
  text-transform: uppercase;
  transition: all 0.3s ease;

  &:hover {
    border-color: var(--primary_transparent);
    color: var(--primary);
  }

  ${({ $active }) =>
    $active &&
    css`
      background-color: transparent;
      border-bottom: 5px solid var(--primary);
      border-radius: 0;
      color: var(--primary);
      font-weight: bold;
    `};
`;
