import { ILoadingSpinner } from './types';
import { Loader } from './styled';

export const LoadingSpinner = ({ color = 'var(--light_01)' }: ILoadingSpinner): JSX.Element => {
  return (
    <Loader color={color}>
      <span></span>
      <span></span>
      <span></span>
    </Loader>
  );
};
