import styled from 'styled-components';

export const StyledLiveLink = styled.a`
  color: var(--primary);
  background-color: transparent;
  border: 2px solid var(--primary);
  border-radius: 9px;
  margin: 1rem 0.5rem;
  padding: 0.5rem 1rem;
  text-transform: uppercase;

  width: 11rem;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.6rem;

  &:hover {
    border: 3px solid var(--primary);
    color: var(--primary);
    font-weight: bold;
    text-decoration: none;
    margin: 0.9rem 0.4rem;
  }
`;
