import { Wrapper } from './styled';
import { ImageWrapperProps } from './types';

export const ImageWrapper = ({
  children,
  className = '',
  width,
}: ImageWrapperProps): JSX.Element => {
  return (
    <Wrapper className={className} width={width}>
      {children}
    </Wrapper>
  );
};
