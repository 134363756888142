import '../styles/globals.css';
import '@fortawesome/fontawesome-svg-core/styles.css';

import { Flex, LoadingSpinner } from 'components';
import { useCallback, useEffect, useState } from 'react';

import type { AppProps } from 'next/app';
import NextProgress from 'nextjs-progressbar';
import NextScript from 'next/script';
import { config } from '@fortawesome/fontawesome-svg-core';
import { pageview } from 'lib/gtagHelper';
import { useRouter } from 'next/router';

// import { usePathname, useSearchParams } from 'next/navigation';


config.autoAddCss = false;

const App = ({ Component, pageProps }: AppProps) => {
  // const pathname = usePathname();
  // const searchParams = useSearchParams();
  const router = useRouter();

  const [isLoading, setIsLoading] = useState(true);

  const handleContextMenu = useCallback((e: MouseEvent) => {
    e.preventDefault()
  }, []);

  useEffect(() => {
    setIsLoading(true);

    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);

  useEffect(() => {
    const handleRouteChange = (url: string) => {
      pageview(process.env.GA_MEASUREMENT_ID || '', url);
    };
    router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);

  /* useEffect(() => {
    const url = searchParams ? pathname + searchParams.toString() : pathname;

    if (process.env.GA_MEASUREMENT_ID && url) pageview(process.env.GA_MEASUREMENT_ID, url);
  }, [pathname, searchParams]); */

  useEffect(() => {
    document.addEventListener('contextmenu', handleContextMenu);

    return function cleanup() {
      document.removeEventListener('contextmenu', handleContextMenu)
    };
  }, [handleContextMenu]);

  return (
    <>
      {isLoading ? (
        <Flex alignItems="center" justifyContent="center" height="100vh" width="100vw">
          <LoadingSpinner color="var(--primary)" />
        </Flex>
      ) : (
        <>
          <NextProgress />
          <Component {...pageProps} />
          <NextScript
            strategy="afterInteractive"
            src={`https://www.googletagmanager.com/gtag/js?id=${process.env.GA_MEASUREMENT_ID}`}/>
          <NextScript id="google-analytics" strategy="afterInteractive"
            dangerouslySetInnerHTML={{
              __html: `
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());

                gtag('consent', 'default', {
                    'analytics_storage': 'denied'
                });

                gtag('config', '${process.env.GA_MEASUREMENT_ID}', {
                    page_path: window.location.pathname,
                });
                `,
            }}
          />
          <NextScript src="https://images.dmca.com/Badges/DMCABadgeHelper.min.js"></NextScript>
        </>
      )}
    </>
  );
}

export default App;
