import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { Testimonial as TestimonialType, testimonials } from 'data';

import { Arrow } from './Arrow';
import { Container } from './styled';
import Slider from 'react-slick';
import { Testimonial } from 'components';
import { useRef } from 'react';

export const Carousel = (): JSX.Element => {
  const sliderRef = useRef(null);

  const settings = {
    slidesToShow: 3,
    slidesToScroll: 3,
    dots: false,
    arrows: true,
    infinite: true,
    lazyload: 'ondemand',
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
          arrows: false,
        },
      },
    ],
    nextArrow: <Arrow direction="right" />,
    prevArrow: <Arrow direction="left" />,
  };

  return (
    <Container>
      <Slider {...settings} ref={sliderRef}>
        {testimonials.map((reference: TestimonialType, index: number) => (
          <Testimonial
            image={reference.image}
            job={reference.job}
            key={index}
            name={reference.name}
            text={reference.text}
          />
        ))}
      </Slider>
    </Container>
  );
};
