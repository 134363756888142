import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  @media only screen and (min-width: 700px) {
    width: 30%;
  }
`;

export const Icon = styled.div`
  display: flex;
  align-self: center;
  margin-bottom: 1rem;
  height: 8.75rem;
  width: 8.75rem;
`;

export const Text = styled.div`
  padding: 1rem 0;
`;

export const Title = styled.div`
  font-size: 1.5rem;
  text-align: center;
  text-transform: none;
`;
