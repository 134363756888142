import styled, { css } from 'styled-components';

const generalStyles = css`
  background-image: var(--gradient_secondary);
  border: 1px solid transparent;
  border-radius: 9px;
  box-shadow: 0 7px 6px -1px var(--shadow_02);
  color: var(--light_01);
  cursor: pointer;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  font-family: var(--heading-font);
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.5;
  text-align: center;
  text-transform: uppercase;

  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    color: var(--light_01);
    transform: translate(-0.2rem, -0.2rem);
  }
`;

const outlinedStyles = css`
  background-image: none;
  background-color: var(--light_01);
  border: 2px solid var(--primary);
  border-radius: 9px;
  box-shadow: none;
  color: var(--primary);
  font-family: var(--content-font);

  &:hover {
    background-color: var(--primary);
    color: var(--light_01);
    font-weight: bold;
    text-decoration: none;
    transform: none;
  }
`;

export const StyledLink = styled.div<{ $outlined: boolean }>`
  ${generalStyles};

  ${({ $outlined }) => $outlined && outlinedStyles};
`;

export const StyledButton = styled.button<{ $outlined: boolean }>`
  ${generalStyles};

  ${({ $outlined }) => $outlined && outlinedStyles};
`;
