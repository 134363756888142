import { ArrowProps } from './types';
import { Icon } from 'components';
import { StyledArrow } from './styled';

export const Arrow = ({ direction, onClick }: ArrowProps): JSX.Element => (
  <StyledArrow direction={direction} onClick={onClick}>
    <Icon
      color="var(--grey)"
      type={`chevron${direction.charAt(0).toUpperCase() + direction.slice(1)}`}
      height="2rem"
      width="0.9rem"
    />
  </StyledArrow>
);
