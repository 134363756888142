import { GridProps } from './types';
import { StyledGrid } from './styled';

export const Grid = ({
  centered = false,
  children,
  className = '',
  gridConfig,
  margin = '0',
}: GridProps): JSX.Element => {
  const { sm, md, lg } = gridConfig;
  return (
    <StyledGrid centered={centered} className={className} $sm={sm} $md={md} $lg={lg} margin={margin}>
      {children}
    </StyledGrid>
  );
};
