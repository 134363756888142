import { Link, StyledTabs, Tab } from './styled';

import { TabsProps } from './types';

export const Tabs = ({
  activeTab,
  handleActiveTab,
  tabs,
}: TabsProps): JSX.Element => (
  <StyledTabs>
    {tabs.map((tab: string, index: number) => (
      <Tab key={index}>
        <Link
          $active={index === activeTab}
          id={tab.toLowerCase()}
          onClick={() => handleActiveTab(index)}
        >
          {tab}
        </Link>
      </Tab>
    ))}
  </StyledTabs>
);
