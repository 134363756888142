import { Container } from './styled';
import { FlexProps } from './types';

export const Flex = ({
  alignItems,
  breakpoint = '700px',
  children,
  direction,
  gap,
  height = 'auto',
  justifyContent,
  mobileDirectionChange = false,
  margin = '0',
  width = 'auto',
}: FlexProps): JSX.Element => (
  <Container
    $alignItems={alignItems}
    $breakpoint={breakpoint}
    $direction={direction}
    $gap={gap}
    $height={height}
    $justifyContent={justifyContent}
    $margin={margin}
    $mobileDirectionChange={mobileDirectionChange}
    $width={width}
  >
    {children}
  </Container>
);
