import styled, { keyframes } from 'styled-components';

const bounce = keyframes`
  to {
    transform: translate3d(0, -1rem, 0);
  }
`;

export const Loader = styled.div<{ color: string }>`
  display: flex;
  align-items: center;
  justify-content: center;

  height: 2rem;
  margin-top: 2rem;

  & > span {
    background-color: ${({ color }) => color && color};
    border-radius: 50%;
    margin: 0 10px;

    display: inline-block;

    height: 0.75rem;
    width: 0.75rem;

    animation: ${bounce} 0.5s infinite alternate;
    transform: translate3d(0);
  }

  & > span:nth-child(2) {
    animation-delay: 0.15s;
  }

  & > span:nth-child(3) {
    animation-delay: 0.25s;
  }
`;
