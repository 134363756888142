import styled from 'styled-components';

export const Container = styled.div`
  .slick-slider {
    padding: 1.75rem;

    @media only screen and (min-width: 936px) {
      margin-left: auto;
      margin-right: auto;
      padding: 2rem 8.25rem;

      max-width: 75rem;
    }
  }

  .slick-dots {
    position: unset;

    @media only screen and (min-width: 700px) {
      margin-top: 0.75rem;
    }
  }

  .slick-dots li button::before {
    color: var(--primary);
    font-size: 0.7rem;
  }

  .slick-dots li.slick-active button::before {
    color: var(--primary);
    font-size: 0.7rem;
  }

  .slick-next:before, .slick-prev:before {
    font-family: var(--content-font) !important;
    font-display: swap;
  }
`;

export const StyledArrow = styled.div<{ direction: 'left' | 'right' }>`
  position: absolute;
  bottom: 1.75rem;

  right: ${({ direction }) => direction === 'right' && '1.75rem'};

  z-index: 2;
`;
