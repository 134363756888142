import { LogoProps } from './types';
import NextImage from 'next/image';
import { StyledLogo } from './styled';
// import { SvgLogo } from './SvgLogo';
import logo from 'assets/logo/logo.svg';

export const Logo = ({
  height = '2.25rem',
  width = 'auto',
}: LogoProps): JSX.Element => (
  <StyledLogo aria-label="Navigate to the homepage" href="/#top" height={height} width={width}>
    <NextImage
      alt="Logo"
      src={logo}
      style={ { objectFit: 'contain' } }
    />
  </StyledLogo>
);
