import * as React from 'react';

import { SVGProps } from 'react';

const SvgLinkedin = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 34 34" {...props}>
    <path
      d="M29.846 0A4.156 4.156 0 0 1 34 4.154v25.692A4.156 4.156 0 0 1 29.846 34H4.154A4.156 4.156 0 0 1 0 29.846V4.154A4.156 4.156 0 0 1 4.154 0h25.692zM11.05 28.05v-15.3h-5.1v15.3h5.1zm17 0v-8.572c0-4.592-2.473-6.728-5.77-6.728-2.66 0-3.851 1.45-4.518 2.468v-2.116H12.75c.066 1.402 0 14.948 0 14.948h5.012v-8.348c0-.447.032-.894.165-1.213.362-.893 1.187-1.817 2.572-1.817 1.813 0 2.539 1.371 2.539 3.38v7.998h5.012zM8.943 5.95c-1.81 0-2.993 1.104-2.993 2.55 0 1.416 1.147 2.55 2.923 2.55h.034c1.845 0 2.993-1.134 2.993-2.55-.034-1.444-1.145-2.547-2.957-2.55z"
      fill="var(--primary_light_03)"
      fillRule="evenodd"
      opacity={0.7}
    />
  </svg>
);

export default SvgLinkedin;
