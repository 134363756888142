import * as React from 'react';

import { SVGProps } from 'react';

const SvgMail = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 34 34" {...props}>
    <path
      d="M29.846 0A4.156 4.156 0 0 1 34 4.154v25.692A4.156 4.156 0 0 1 29.846 34H4.154A4.156 4.156 0 0 1 0 29.846V4.154A4.156 4.156 0 0 1 4.154 0h25.692zm-12.53 5.379H17c-3.156 0-3.552.013-4.791.07-1.237.056-2.082.253-2.821.54a5.697 5.697 0 0 0-2.059 1.34 5.697 5.697 0 0 0-1.34 2.059c-.287.739-.484 1.584-.54 2.82l-.026.584-.011.017.01.008c-.033.827-.042 1.533-.043 3.866V17c0 3.156.013 3.552.07 4.791.056 1.237.253 2.082.54 2.821a5.697 5.697 0 0 0 1.34 2.059 5.696 5.696 0 0 0 2.059 1.34c.739.287 1.584.484 2.82.54 1.197.055 1.608.07 4.475.07H17c3.156 0 3.552-.013 4.791-.07 1.237-.056 2.082-.253 2.821-.54a5.697 5.697 0 0 0 2.059-1.34 5.697 5.697 0 0 0 1.34-2.059c.287-.739.484-1.584.54-2.82.055-1.197.07-1.608.07-4.475V17c0-2.588-.009-3.32-.043-4.183l.01-.008-.011-.017-.026-.583c-.056-1.237-.253-2.082-.54-2.821a5.697 5.697 0 0 0-1.34-2.059 5.697 5.697 0 0 0-2.059-1.34c-.739-.287-1.584-.484-2.82-.54-1.197-.055-1.608-.07-4.475-.07zm9.211 11.017v1.208c-.002 2.554-.016 2.951-.068 4.092-.051 1.133-.24 1.748-.4 2.158a3.6 3.6 0 0 1-.869 1.336 3.6 3.6 0 0 1-1.336.87c-.41.158-1.025.348-2.158.4-1.14.051-1.538.065-4.092.067h-1.208c-2.554-.002-2.951-.016-4.092-.068-1.133-.051-1.748-.24-2.158-.4a3.6 3.6 0 0 1-1.336-.869 3.6 3.6 0 0 1-.87-1.336c-.158-.41-.348-1.025-.4-2.158-.052-1.141-.065-1.538-.067-4.092v-1.208c0-.937.003-1.583.008-2.083l8.93 6.496a1 1 0 0 0 1.177 0l8.931-6.496c.005.5.007 1.146.008 2.083zM21.696 7.54c1.133.051 1.748.24 2.158.4.542.21.93.462 1.336.869.407.407.659.794.87 1.336.138.358.3.874.374 1.755L17 18.764 7.566 11.9c.073-.881.236-1.397.375-1.755A3.6 3.6 0 0 1 8.81 8.81a3.601 3.601 0 0 1 1.336-.87c.41-.158 1.025-.348 2.158-.4 1.183-.053 1.567-.066 4.385-.067H17c3.103 0 3.47.012 4.696.068z"
      fill="var(--primary_light_03)"
      fillRule="evenodd"
      opacity={0.7}
    />
  </svg>
);

export default SvgMail;
