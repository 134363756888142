import { StyledFlexProps } from './types';
import styled from 'styled-components';

export const Container = styled.div<StyledFlexProps>`
  display: flex;
  align-items: ${({ $alignItems }) => ($alignItems ? $alignItems : 'flex-start')};
  justify-content: ${({ $justifyContent }) =>
    $justifyContent ? $justifyContent : 'flex-start'};
  gap: ${({ $gap }) => ($gap ? $gap : '0')};
  flex-direction: ${({ $direction }) => ($direction ? $direction : 'row')};
  margin: ${({ $margin }) => $margin && $margin};
  height: ${({ $height }) => $height && $height};
  width: ${({ $width }) => $width && $width};

  @media only screen and (min-width: ${({ $breakpoint }) =>
    $breakpoint && $breakpoint}) {
    ${({ $direction, $mobileDirectionChange }) =>
    $mobileDirectionChange &&
      `flex-direction: ${
        $direction === 'row' ? 'column' : $direction === 'column' && 'row'
      }`}
  }
`;
