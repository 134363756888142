import styled from 'styled-components';

export const Wrapper = styled.div<{
  $flex: boolean;
  $maxWidth: string;
  $mobileColumn: boolean;
  $padded: boolean;
}>`
  display: ${({ $flex }) => ($flex ? 'flex' : 'block')};
  flex-direction: ${({ $mobileColumn }) => ($mobileColumn ? 'column' : 'row')};

  padding: ${({ $padded }) => ($padded ? '1.75rem' : '0')};

  @media only screen and (min-width: 936px) {
    flex-direction: row;

    margin-left: auto;
    margin-right: auto;
    max-width: ${({ $maxWidth }) => $maxWidth && $maxWidth};
  }
`;
