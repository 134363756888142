import { StyledButton, StyledLink } from './styled';

import { ButtonProps } from './types';
import Link from 'next/link';

export const Button = ({
  ariaLabel = '',
  className = '',
  href,
  label,
  onClick,
  outlined = false,
  target = '_self',
}: ButtonProps): JSX.Element => {
  if (href) {
    return (
      <Link href={href} passHref target={target}>
        <StyledLink
          aria-label={ariaLabel}
          className={className}
          $outlined={outlined}
        >
          {label}
        </StyledLink>
      </Link>
    );
  }

  return (
    <StyledButton
      aria-label={ariaLabel}
      className={className}
      onClick={onClick}
      $outlined={outlined}
    >
      {label}
    </StyledButton>
  );
};
