import { Button } from 'components';
import styled from 'styled-components';

export const Content = styled.div`
  padding: 1.75rem;

  @media only screen and (min-width: 936px) {
    padding: 0.5rem 8.25rem 2rem;
    margin-left: auto;
    margin-right: auto;
    max-width: 90.5rem;
  }
`;

export const ProjectGrid = styled.div`
  align-items: center;

  display: grid;
  grid-gap: 1rem;

  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
`;

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  margin: 1.5rem 0 0.5rem;
`;

export const SeeMoreButton = styled(Button)`
  min-width: 8rem;
  padding: 0.5rem 2rem;
  width: 10rem;
`;
