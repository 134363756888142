import { StyledTitle } from './styled';
import { TitleProps } from './types';

export const Title = ({ children }: TitleProps): JSX.Element => {
  return (
    <StyledTitle>
      <h2>{children}</h2>
    </StyledTitle>
  );
};
