import { StyledIconProps } from './types';
import styled from 'styled-components';

export const StyledIcon = styled.span<StyledIconProps>`
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    height: ${({ height }) => height};
    width: ${({ width }) => width};
  }

  @media only screen and (min-width: 936px) {
    svg {
      transform: ${({ $scaleOnDesktop }) => $scaleOnDesktop && 'scale(1.25)'};
    }
  }
`;
