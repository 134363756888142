import styled from 'styled-components';

export const Wrapper = styled.div<{ height: string }>`
  border-radius: 9px;
  box-shadow: 0px 3px 4px 0px var(--shadow_03);
  cursor: 'pointer';
  overflow: hidden;
  position: relative;

  height: ${({ height }) => (height === 'portrait' ? '24rem' : '15.6rem')};
  width: 100%;

  img {
    transition: 1s ease;
  }

  &:hover img {
    transform: scale(1.25);
  }
`;

export const Caption = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: var(--primary_transparent);
  border-radius: 0 0 9px 9px;
  color: var(--light_01);
  font-family: var(--heading-font);
  font-size: 1.5rem;
  text-align: center;

  position: absolute;
  bottom: 0;

  height: 5rem;
  width: 100%;
`;
