import styled from 'styled-components';

export const Card = styled.div`
  height: 34rem;

  @media only screen and (min-width: 700px) {
    height: 53rem;
  }

  @media only screen and (min-width: 800px) {
    height: 46rem;
  }

  @media only screen and (min-width: 900px) {
    height: 42rem;
  }

  @media only screen and (min-width: 936px) {
    height: 52rem;
  }

  @media only screen and (min-width: 1024px) {
    height: 46rem;
  }

  @media only screen and (min-width: 1201px) {
    height: 38rem;
  }
`;

export const Name = styled.h3`
  font-size: 1.5rem;
  font-weight: bold;
  text-transform: uppercase;

  @media only screen and (min-width: 936px) {
    color: var(--primary);
    font-size: 1.25rem;
    text-transform: unset;
  }
`;

export const Job = styled.h4`
  color: var(--shadow_04);
  font-family: var(--content-font);
  font-size: 1rem;
  font-weight: 600;

  height: 2rem;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const ImageWrapper = styled.div`
  align-self: center;
  border: 2px solid var(--primary);
  border-radius: 50%;
  overflow: hidden;

  height: 5rem;
  width: 5rem;

  position: relative;
`;

export const Referee = styled.div`
  align-self: center;
  padding: 1rem 1rem 0.5rem;
  text-align: center;
`;

export const Quote = styled.div`
  @media only screen and (min-width: 700px) {
    padding: 1.5rem;
  }
`;
