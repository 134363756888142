import { Actions, StyledBanner, StyledButton } from './styled';
import { getLocalStorage, setLocalStorage } from 'lib/storageHelper';
import { useEffect, useState } from 'react';

export const CookieBanner = (): JSX.Element | null => {
  const [cookieConsent, setCookieConsent] = useState<'granted' | 'denied' | undefined>(undefined);

  useEffect (() => {
    const storedCookieConsent = getLocalStorage('cookie_consent', null);

    setCookieConsent(storedCookieConsent)
  }, []);


  useEffect(() => {
    if (window.gtag) {
      window.gtag('consent', 'update', {
        'analytics_storage': cookieConsent ?? 'denied'
      });
    }

    setLocalStorage('cookie_consent', cookieConsent);
  }, [cookieConsent]);

  if (!cookieConsent) {
    return (
      <StyledBanner>
        <div>
        This site uses cookies.
        </div>
        <Actions>
          <StyledButton label="Reject" onClick={() => setCookieConsent('denied')} />
          <StyledButton label="Accept" onClick={() => setCookieConsent('granted')} />
        </Actions>
      </StyledBanner>
    );
  }

  return null;
};
