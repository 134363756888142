import styled from 'styled-components';

export const StyledTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  height: 4rem;

  background-image: var(--gradient_primary);
  color: var(--light_01);

  h2 {
    margin-bottom: 0;

    @media only screen and (min-width: 936px) {
      font-size: 2.75rem;
      padding-bottom: 0.25rem;
    }
  }
`;
