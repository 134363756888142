import { Container } from './styled';
import Link from 'next/link';
import NextImage from 'next/image';
import { PropertyReferenceProps } from './types';
import { getLogoSrc } from './utils';
import { useMemo } from 'react';

export const PropertyReference = ({
  caption = true,
  company,
  link,
  project,
}: PropertyReferenceProps): JSX.Element => {
  const logoSrc = useMemo(() => getLogoSrc(company), [company]);

  const renderLogo = useMemo((): JSX.Element | null => {
    if (!link) {
      return (
        <NextImage
          alt={`${company} Logo`}
          placeholder="blur"
          src={logoSrc}
          style={{ height: '100%', objectFit: 'contain', width: '100%' }}
        />
      );
    }

    return (
      <Link aria-label={`Visit the website of ${company}`} href={link} passHref>
        <NextImage
          alt={`${company} Logo`}
          placeholder="blur"
          src={logoSrc}
          style={{ height: '100%', objectFit: 'contain', width: '100%' }}
        />
      </Link>
    );
  }, [company, link, logoSrc]);

  const renderCaption = useMemo(() => {
    if (!caption) return null;

    return (
      <p>
        {project} is property of {company}
      </p>
    );
  }, [caption, company, project]);

  return (
    <Container>
      {renderLogo}
      {renderCaption}
    </Container>
  );
};
