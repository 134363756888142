import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  height: 7.5rem;
  max-width: 18.75rem;

  a {
    height: 100%;
    width: 100%;
  }
`;
