import { Container, Icon, Text, Title } from './styled';

import NextImage from 'next/image';
import { SkillProps } from './types';

export const Skill = ({ icon, text, title }: SkillProps): JSX.Element => (
  <Container>
    <Icon>
      <NextImage
        alt={title}
        src={icon}
        style={ { height: '100%', objectFit: 'contain', width: '100%' } }
      />
    </Icon>
    <Title>{title}</Title>
    <Text>{text}</Text>
  </Container>
);
