import { StyledGridProps } from './types';
import styled from 'styled-components';

export const StyledGrid = styled.div<StyledGridProps>`
  display: grid;
  grid-template-columns: ${({ $sm }) => $sm.col && `repeat(${$sm.col}, auto)`};
  grid-template-rows: ${({ $sm }) => $sm.row && `repeat(${$sm.row}, auto)`};
  grid-gap: ${({ $sm }) => $sm.gap && `${$sm.gap}rem`};
  margin: ${({ margin }) => margin && margin};
  justify-content: ${({ centered }) => (centered ? 'center' : 'normal')};

  @media only screen and (min-width: 700px) {
    grid-template-columns: ${({ $md }) => $md.col && `repeat(${$md.col}, auto)`};
    grid-template-rows: ${({ $md }) => $md.row && `repeat(${$md.row}, auto)`};
    grid-gap: ${({ $md }) => $md.gap && `${$md.gap}rem`};

    justify-content: ${({ centered }) =>
    centered ? 'space-between' : 'normal'};
  }

  @media only screen and (min-width: 936px) {
    grid-template-columns: ${({ $lg }) => $lg.col && `repeat(${$lg.col}, auto)`};
    grid-template-rows: ${({ $lg }) => $lg.row && `repeat(${$lg.row}, auto)`};
    grid-gap: ${({ $lg }) => $lg.gap && `${$lg.gap}rem`};
  }
`;
