import FsLightbox from 'fslightbox-react';
import { LightboxProps } from './types';

export const Lightbox = ({ controller, sources }: LightboxProps): JSX.Element => {
  return (
    <FsLightbox
      toggler={controller.toggler}
      slide={controller.slide}
      sources={sources}
    />
  );
}
