import cecilia from 'assets/images/testimonials/Cecilia.png';
import isaac from 'assets/images/testimonials/Isaac.png';
import michael from 'assets/images/testimonials/Michael.png';
import nigel from 'assets/images/testimonials/Nigel.png';
import norbert from 'assets/images/testimonials/Norbert.png';
import yvonne from 'assets/images/testimonials/Yvonne.png';

export type Testimonial = {
  name: string;
  image: StaticImageData;
  job: string;
  text: string;
};

export const testimonials = [
  {
    name: 'Nigel Li',
    image: nigel,
    job: 'Art Director, Storm 8',
    text: '"Ziv is awesome.  We have worked on multiple projects together, and he is always growing, learning, and becoming a better artist/game maker.  He thrives under pressure, and is passionate about the business.  He has always been consistent in his deliveries.  He contributes ideas, helps with UI, and communicates well.  I highly recommend Ziv to any team he joins!"',
  },
  {
    name: 'Michael Lee',
    image: michael,
    job: 'Product Manager, GC Turbo Inc.',
    text: '"What stands out about Ziv is his rabid curiosity. He is constantly acquiring new skills, learning new tools and techniques, and asking unexpected questions to become a more well-rounded artist and designer. The Pokémon Company required our artists to produce content to the highest standards under tight time constraints. Ziv definitely met the challenge, designing much of the UI/UX flows that were the connective tissue of the game. Ziv earns my highest recommendation for any UI/UX artist or designer position."',
  },
  {
    name: 'Cecilia Wong',
    image: cecilia,
    job: 'Art Director, Facebook',
    text: '"Ziv has been on my team for over a year and during that time, he\'s been an amazing asset and a great contributor. Not only does he have the very solid artistic foundation you would expect out of an artist, he is great at interfacing with project managers, UI designers, and game designers and is all around a great guy to work with."',
  },
  {
    name: 'Norbert Kalman',
    image: norbert,
    job: 'Director of Product Management, Storm 8',
    text: '"I had the pleasure to work with Ziv on several major features at Storm8, including Candy Blast Mania. Ziv understands the product vision very well, brings a lot of great ideas to the table and delivers delightful results! He works very independently and efficiently as well. Without a doubt, Ziv will be an excellent addition to any Art team he choses to join!"',
  },
  {
    name: 'Isaac Orloff',
    image: isaac,
    job: 'Visual Development Artist, Paramount Pictures',
    text: '"Ziv is one of the nicest people I have worked with. Very friendly and dedicated to improving his craft. I often spotted Ziv working after hours on drawing exercises he chose for himself to learn new techniques. A great team member, would gladly work with him again any time!"',
  },
  {
    name: 'Yvonne Phan',
    image: yvonne,
    job: 'Lead UI Designer, GC Turbo Inc.',
    text: '"Ziv doesn\’t shy away from hard work, he\’s dedicated to always learning more, and his curiosity keeps him as updated as possible. He\’s always open and willing to learn. Ziv has no ego and that makes him an incredible team member to have. Ziv not only delivers high polish work, but has the ability to juggle many projects at once and always delivers in a timely manner. If you\’re looking for a great team member, he\’s your guy."',
  },
];
