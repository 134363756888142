import * as React from 'react';

import { SVGProps } from 'react';

const SvgInstagram = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 34 34" {...props}>
    <path
      d="M29.846 0A4.156 4.156 0 0 1 34 4.154v25.692A4.156 4.156 0 0 1 29.846 34H4.154A4.156 4.156 0 0 1 0 29.846V4.154A4.156 4.156 0 0 1 4.154 0h25.692zM17 5.379c-3.156 0-3.552.013-4.791.07-1.237.056-2.082.253-2.821.54a5.697 5.697 0 0 0-2.059 1.34 5.697 5.697 0 0 0-1.34 2.059c-.287.739-.484 1.584-.54 2.82-.057 1.24-.07 1.636-.07 4.792 0 3.156.013 3.552.07 4.791.056 1.237.253 2.082.54 2.821a5.697 5.697 0 0 0 1.34 2.059 5.696 5.696 0 0 0 2.059 1.34c.739.287 1.584.484 2.82.54 1.24.057 1.636.07 4.792.07 3.156 0 3.552-.013 4.791-.07 1.237-.056 2.082-.253 2.821-.54a5.697 5.697 0 0 0 2.059-1.34 5.697 5.697 0 0 0 1.34-2.059c.287-.739.484-1.584.54-2.82.057-1.24.07-1.636.07-4.792 0-3.156-.013-3.552-.07-4.791-.056-1.237-.253-2.082-.54-2.821a5.697 5.697 0 0 0-1.34-2.059 5.697 5.697 0 0 0-2.059-1.34c-.739-.287-1.584-.484-2.82-.54-1.24-.057-1.636-.07-4.792-.07zm0 2.094c3.103 0 3.47.012 4.696.068 1.133.051 1.748.24 2.158.4.542.21.93.462 1.336.869.407.407.659.794.87 1.336.158.41.348 1.025.4 2.158.055 1.225.067 1.593.067 4.696 0 3.103-.012 3.47-.068 4.696-.051 1.133-.24 1.748-.4 2.158a3.6 3.6 0 0 1-.869 1.336 3.6 3.6 0 0 1-1.336.87c-.41.158-1.025.348-2.158.4-1.225.055-1.593.067-4.696.067-3.103 0-3.47-.012-4.696-.068-1.133-.051-1.748-.24-2.158-.4a3.6 3.6 0 0 1-1.336-.869 3.6 3.6 0 0 1-.87-1.336c-.158-.41-.348-1.025-.4-2.158-.055-1.225-.067-1.593-.067-4.696 0-3.103.012-3.47.067-4.696.052-1.133.242-1.748.4-2.158a3.6 3.6 0 0 1 .87-1.336 3.601 3.601 0 0 1 1.336-.87c.41-.158 1.025-.348 2.158-.4 1.225-.055 1.593-.067 4.696-.067zm0 3.56a5.968 5.968 0 1 0 0 11.935 5.968 5.968 0 0 0 0-11.936zm0 9.84a3.874 3.874 0 1 1 0-7.747 3.874 3.874 0 0 1 0 7.748zm7.598-10.076a1.395 1.395 0 1 1-2.79 0 1.395 1.395 0 0 1 2.79 0z"
      fill="var(--primary_light_03)"
      fillRule="evenodd"
      opacity={0.7}
    />
  </svg>
);

export default SvgInstagram;
