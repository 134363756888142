import Link from 'next/link';
import { Reference } from './styled';
import { ReferenceLinkProps } from './types';

export const ReferenceLink = ({ link, name }: ReferenceLinkProps): JSX.Element => {
  return (
    <Link href={link} passHref>
      <Reference>{name}</Reference>
    </Link>
  );
}