import { ContentProps } from './types';
import { Wrapper } from './styled';

export const Content = ({
  children,
  className = '',
  flex = false,
  maxWidth = '75rem',
  mobileColumn = false,
  padded = false,
}: ContentProps): JSX.Element => (
  <Wrapper
    className={className}
    $flex={flex}
    $maxWidth={maxWidth}
    $mobileColumn={mobileColumn}
    $padded={padded}
  >
    {children}
  </Wrapper>
);
