import { Button } from 'components';
import styled from 'styled-components';

export const StyledBanner = styled.div`
  position: fixed;
  bottom: 0;
  right: 0;

  display: flex;
  flex-direction: column;
  gap: 1rem;

  background-color: var(--light_03);
  padding: 1.4rem;
  padding-bottom: 2.4rem;

  width: 100%;
  z-index: 100;

  @media only screen and (min-width: 700px) {
    bottom: 1rem;
    right: 1rem;

    flex-direction: row;
    align-items: center;
    gap: 4rem;

    border-radius: 9px;
    box-shadow: 0 7px 6px -1px var(--shadow_02);
    padding-bottom: 1.4rem;

    width: unset;
  }
`;

export const Actions = styled.div`
  display: flex;
  gap: 1rem;
`;

export const StyledButton = styled(Button)`
  padding: 0.4rem 1.6rem;

  width: calc(50% - 0.5rem);

  @media only screen and (min-width: 700px) {

  }
`;