import { ButtonWrapper, Content, ProjectGrid, SeeMoreButton } from './styled';

import { Project } from 'components';
import { ProjectListProps } from './types';
import { Project as ProjectType } from 'data';

export const ProjectList = ({
  className = '',
  height = 'portrait',
  id,
  link = undefined,
  projects,
  seeMoreButton = false,
}: ProjectListProps): JSX.Element => (
  <Content className={className} id={id}>
    <ProjectGrid>
      {projects.map((project: ProjectType, index: number): JSX.Element => {
        const { caption = '', image, link, name } = project;
        return (
          <Project
            caption={caption}
            height={height}
            image={image}
            key={index}
            link={link}
            name={name}
          />
        );
      })}
    </ProjectGrid>
    {seeMoreButton && (
      <ButtonWrapper className="text-center">
        <SeeMoreButton ariaLabel="See more projects" href={link} label="see more" outlined />
      </ButtonWrapper>
    )}
  </Content>
);
