import { Icon } from 'components';
import { LiveLinkProps } from './types';
import { StyledLiveLink } from './styled';

export const LiveLink = ({
  children,
  icon = 'apple',
  link,
}: LiveLinkProps): JSX.Element => (
  <StyledLiveLink aria-label="Play the game" href={link} target="_blank">
    <Icon color="var(--primary)" height="1.5rem" type={icon} width="1.5rem" />
    {children}
  </StyledLiveLink>
);
