import {
  Card,
  Header,
  ImageWrapper,
  Job,
  Name,
  Quote,
  Referee,
} from './styled';

import NextImage from 'next/image';
import { TestimonialProps } from './types';

export const Testimonial = ({
  name,
  image,
  job,
  text,
}: TestimonialProps): JSX.Element => (
  <Card>
    <Header>
      <ImageWrapper>
        <NextImage
          alt={`${name}\'s Avatar`}
          fill
          placeholder="blur"
          src={image}
          style={ { objectFit: 'contain' } }
        />
      </ImageWrapper>
      <Referee>
        <Name>{name}</Name>
        <Job>{job}</Job>
      </Referee>
    </Header>
    <Quote>{text}</Quote>
  </Card>
);
